import React, { useState } from "react"

import Layout from "../templates/Page"
import Header from "../components/ContentBuilder/Header"
import HomePreferences from "../components/HomePreferences"
import StayInTouch from "../templates/Includes/StayInTouch"

const MyAccountPage = () => {
  const [currentTab, setCurrentTab] = useState(0)

  const tabs = ["My Account", "My Preferences"]

  return (
    <Layout hideHeader>
      <Header isLogin />

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              My Accounts
            </li>
          </ol>
        </nav>
        <div className="row no-gutters justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8">
            <div className="my-account-header-wrap">
              {tabs.map((item, idx) => (
                <div key={idx}
                  className={
                    currentTab === idx
                      ? "my-account-header active"
                      : "my-account-header"
                  }
                  onClick={() => {
                    setCurrentTab(idx)
                  }}
                >
                  <div className="my-account-header-title">{item}</div>
                </div>
              ))}
            </div>
            <div className="account-avt-wrap">
              <div className="account-avt">
                <div className="account-avt-inner">
                  <span>CA</span>
                  <img src="/img/christop-3.png"></img>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`account-setting ${currentTab === 0 ? 'd-block' : 'd-none'}`}>
          <div className="row justify-content-center no-gutters">
            <div className="col-12 col-lg-10 col-xl-8">
              <div className="account-wrap">
                <div className="border-bottom-7px">
                  <h2 className="product-name-title text-left text-lg-center">My Settings</h2>
                </div>
                <div className="account-field">
                  <div className="form-group toggle-label">
                    <input name="Name" type="text" className="form-control" id="Name" placeholder="Name *"></input>
                    <label htmlFor="Name">Name *</label>
                  </div>
                  <div className="form-group toggle-label">
                    <input name="Email" type="email" className="form-control" id="Email" placeholder="Email *"></input>
                    <label htmlFor="Email">Email *</label>
                  </div>
                  <div className="form-group toggle-label">
                    <input name="Address" type="text" className="form-control" id="Address" placeholder="Address *"></input>
                    <label htmlFor="Address">Address *</label>
                  </div>
                  <div className="form-group toggle-label">
                    <input name="MobileNumber" type="text" className="form-control" id="MobileNumber" placeholder="Mobile Number *"></input>
                    <label htmlFor="MobileNumber">Mobile Number *</label>
                  </div>
                  <div className="form-group toggle-label has-reset">
                    <input name="Password" type="password" className="form-control" id="Password" placeholder="Password *"></input>
                    <label htmlFor="Password">Password *</label>
                    <a href="#" className="reset-btn">Reset</a>
                  </div>
                  <div className="custom-control custom-checkbox has-span mb-2">
                    <span>Quick Access</span>
                    <input name="QuickAccess"type="checkbox" className="custom-control-input" value="QuickAccess" id="QuickAccess" />
                    <label className="custom-control-label" htmlFor="QuickAccess">Enable quick access (4 digit pin) login</label>
                  </div>
                </div>
              </div>
              <div className="account-btn-group">
                <a href="#" className="btn btn-black btn-lg btn-w210">SAVE CHANGES</a>
                <a href="#" className="btn btn-outline-black btn-lg btn-w210">LOG OUT</a>
              </div>
            </div>
          </div>
        </div>

        <div className={`account-preferences ${currentTab === 1 ? 'd-block' : 'd-none'}`}>
          <div className="row justify-content-center no-gutters">
            <div className="col-12 col-lg-10 col-xl-8">
              <div className="account-wrap">
                <div className="border-bottom-7px">
                  <h2 className="product-name-title text-left text-lg-center">My Preferences</h2>
                </div>
                <div className="filters has-span">
                  <span>Home preferences</span>
                  <ul className="filters-list">
                    <li>
                      <div className="filter-item">
                        <span className="mr-2">Knockdown Rebuild</span>
                        <button type="button" className="filter-icon">
                          <svg width="11" height="11"><use xlinkHref="/img/icons.svg#icon-times"></use></svg>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div className="filter-item">
                        <span className="mr-2">I Have Kids</span>
                        <button type="button" className="filter-icon">
                          <svg width="11" height="11"><use xlinkHref="/img/icons.svg#icon-times"></use></svg>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div className="filter-item">
                        <span className="mr-2">Home Office</span>
                        <button type="button" className="filter-icon">
                          <svg width="11" height="11"><use xlinkHref="/img/icons.svg#icon-times"></use></svg>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div className="filter-item">
                        <span className="mr-2">3 Bedrooms</span>
                        <button type="button" className="filter-icon">
                          <svg width="11" height="11"><use xlinkHref="/img/icons.svg#icon-times"></use></svg>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div className="filter-item">
                        <span className="mr-2">Media Room</span>
                        <button type="button" className="filter-icon">
                          <svg width="11" height="11"><use xlinkHref="/img/icons.svg#icon-times"></use></svg>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div className="filter-item">
                        <span className="mr-2">Patio</span>
                        <button type="button" className="filter-icon">
                          <svg width="11" height="11"><use xlinkHref="/img/icons.svg#icon-times"></use></svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="link-group has-span">
                  <span>Linked Account</span>
                  <div className="link-item">
                    <p>Brad Garbutt</p>
                    <a href="#">Unlink</a>
                  </div>
                  <div className="link-item">
                    <p>Justine McPherson</p>
                    <a href="#">Unlink</a>
                  </div>
                </div>

                <div className="checkbox-group has-span">
                  <span>Marketing Preferences</span>
                  <div className="custom-control custom-checkbox mb-2">
                    <input name="Subscribe1"type="checkbox" className="custom-control-input" value="Subscribe1" id="Subscribe1" />
                    <label className="custom-control-label" htmlFor="Subscribe1">Subscribe to Marketing</label>
                  </div>
                  <div className="custom-control custom-checkbox mb-2">
                    <input name="Subscribe2"type="checkbox" className="custom-control-input" value="Subscribe2" id="Subscribe2" />
                    <label className="custom-control-label" htmlFor="Subscribe2">Subscribe to Birdy Newsletter</label>
                  </div>
                </div>

                <div className="checkbox-group has-span">
                  <span>Communication Preferences</span>
                  <div className="custom-control custom-checkbox mb-2">
                    <input name="Communicate1"type="checkbox" className="custom-control-input" value="Communicate1" id="Communicate1" />
                    <label className="custom-control-label" htmlFor="Communicate1">Email</label>
                  </div>
                  <div className="custom-control custom-checkbox mb-2">
                    <input name="Communicate2"type="checkbox" className="custom-control-input" value="Communicate2" id="Communicate2" />
                    <label className="custom-control-label" htmlFor="Communicate2">Phone</label>
                  </div>
                </div>
              </div>
              <div className="account-btn-group">
                <a href="#" className="btn btn-black btn-lg btn-w210">UPDATE</a>
                <a href="#" className="btn btn-outline-black btn-lg btn-w210">LOG OUT</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StayInTouch/>
    </Layout>
  )
}

export default MyAccountPage
