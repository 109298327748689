import React, { Component } from "react"
import HomePreferenceItem from "./HomePreferenceItem"

class HomePreferences extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tags: [
        {
          id: "1",
          name: "Knockdown Rebuild",
        },
        {
          id: "2",
          name: "I have kids",
        },
        {
          id: "3",
          name: "Home Office",
        },
      ],
    }
  }

  render() {
    return (
      <>
        <div className="c-home-preferences">
          {this.state.tags.map(tag => (
            <HomePreferenceItem
              key={tag.id}
              title={tag.name}
            ></HomePreferenceItem>
          ))}
        </div>
        <div className="mt-15">
          <div className="c-my-account-checkbox-value d-flex justify-content-start">
            <button className="btn-add">+ Add</button>
          </div>
        </div>
      </>
    )
  }
}

export default HomePreferences
