import React from "react"

const HomePreferenceItem = ({ title }) => {
  return (
    <div className="c-home-preference-item">
      <div className="c-home-preference-title">{title}</div>
      <div className="c-home-preference-icon">X</div>
    </div>
  )
}

export default HomePreferenceItem
